<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Planilla Redireccionamiento</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">
                    Planillas Redireccionamiento
                  </li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="card-title">
                      Formulario {{ accion }} Planilla Redireccionamiento
                    </h5>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <div class="tab-content" id="myTabContent">
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-2" v-if="form.id">
                        <label># Planilla</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.id"
                          disabled
                        />
                      </div>
                      <!-- Fecha Inicial -->
                      <div class="form-group col-md-2">
                        <label>Fecha Inicial</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form.fecha_inicial"
                          :class="
                            $v.form.fecha_inicial.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @input="validaFechasForm"
                          :disabled="[2, 3].includes(form.estado)"
                        />
                      </div>
                      <!-- Fecha Final -->
                      <div class="form-group col-md-2">
                        <label>Fecha Final</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form.fecha_final"
                          :class="
                            $v.form.fecha_final.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @input="validaFechasForm"
                          :disabled="[2, 3].includes(form.estado)"
                        />
                      </div>
                      <!-- Empresa -->
                      <div
                        :class="
                          'form-group' + form.id ? 'col-md-4' : 'col-md-6'
                        "
                      >
                        <label>Empresa</label>
                        <v-select
                          :class="[
                            $v.form.empresa_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          :options="listasForms.empresas"
                          class="form-control form-control-sm p-0"
                          @input="getSelectEmpresas()"
                          :disabled="[2, 3].includes(form.estado)"
                        ></v-select>
                      </div>
                      <!-- Estado -->
                      <div class="form-group col-md-2">
                        <label>Estado</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="form.estado"
                          :class="
                            $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                          "
                          @change="save()"
                          :disabled="[2, 3].includes(form.estado)"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                            :disabled="estado.numeracion == 3"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                        <div
                          class="text-center"
                          v-show="
                            detalles &&
                              detalles.length > 0 &&
                              detalles.every(
                                (det) => det.tarifa == null || det.tarifa == 0
                              )
                          "
                        >
                          <span class="badge bg-warning w-100">
                            Detalle sin tarifa</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row justify-content-center">
                  <button class="btn bg-secondary" @click="back()">
                    <i class="fas fa-reply"></i> Volver
                  </button>
                  <button
                    class="btn bg-primary ml-2"
                    @click="save()"
                    v-if="
                      !$v.form.$invalid &&
                        $store.getters.can(
                          'hidrocarburos.planillaRedireccionamiento.save'
                        )
                    "
                    :disabled="[2, 3].includes(form.estado)"
                  >
                    <i class="fas fa-paper-plane"></i> Guardar
                  </button>
                </div>
              </div>
            </div>
            <!-- Detalles -->
            <div class="card" v-if="this.accion == 'Editar'">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-2">
                      <h5 class="mb-0">Detalle</h5>
                    </div>
                    <div class="col-md-10">
                      <div class="btn-group float-right">
                        <button
                          class="btn bg-info btn-sm"
                          @click="saveDetalle()"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.planillaRedireccionamiento.saveDetalles'
                            ) &&
                              detalles &&
                              detalles.length > 0
                          "
                          :disabled="
                            [2, 3].includes(form.estado) &&
                              detalles &&
                              detalles.length > 0 &&
                              detalles.every(
                                (det) =>
                                  det.planilla_redireccionamiento_id != null
                              )
                          "
                        >
                          <i class="fas fa-save"></i> Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row" v-if="detalles && detalles.length > 0">
                  <div class="col-md-12 table-responsive">
                    <table
                      class="table table-bordered table-striped table-hover table-sm"
                      style="font-size: 90%;"
                    >
                      <thead>
                        <tr>
                          <th class="text-center" colspan="8">
                            <img
                              src="/img/logo--fondo-fronteraenergy.png"
                              alt=""
                              style="width:200px"
                            />
                          </th>
                          <th class="text-center" colspan="8">
                            <p>
                              FORMATO PAGO REDIRECCIONAMIENTO POR CONTINGENCIA
                            </p>
                          </th>
                        </tr>
                        <tr class="bg-dark">
                          <th class="text-center">
                            Acción
                          </th>
                          <th class="text-center" style="min-width: 3em;">
                            Item
                          </th>
                          <th class="text-center" style="min-width: 15em;">
                            Ruta Inicial
                          </th>
                          <th class="text-center" style="min-width: 15em;">
                            Ruta Final
                          </th>
                          <th class="text-center" style="min-width: 15em;">
                            Ruta A Reconocer Planilla
                          </th>
                          <th class="text-center" style="min-width: 13em;">
                            Tipo Vehículo
                          </th>
                          <th class="text-center" style="min-width: 13em;">
                            Producto Transportado
                          </th>
                          <th class="text-center" style="min-width: 10em;">
                            Fecha Cargue
                          </th>
                          <th class="text-center" style="min-width: 8em;">
                            Guía
                          </th>
                          <th class="text-center" style="min-width: 6em;">
                            Placa
                          </th>
                          <th class="text-center" style="min-width: 20em;">
                            Concepto
                          </th>
                          <th class="text-center" style="min-width: 8em;">
                            GSV <small>(BLS)</small>
                          </th>
                          <th class="text-center" style="min-width: 8em;">
                            Tramos A Pagar
                          </th>
                          <th class="text-center" style="min-width: 15em;">
                            Tarifa Aprobado Por Tramo
                          </th>
                          <th class="text-center" style="min-width: 15em;">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(detalle, indice) in detalles" :key="indice">
                          <td class="text-center">
                            <div class="btn-group" v-if="estado_planilla != 2">
                              <button
                                type="button"
                                class="btn btn-sm bg-navy"
                                @click="editPlanilla(indice)"
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.planillaRedireccionamiento.editDetalle'
                                  )
                                "
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                            </div>
                            <div
                              class="btn-group"
                              v-if="
                                id_actualizar_planilla === indice &&
                                  estado_planilla == 2
                              "
                            >
                              <button
                                type="button"
                                class="btn bg-success"
                                v-show="!$v.formPlanilla.$invalid"
                                @click="saveUpdatePlanilla(indice)"
                              >
                                <i class="fas fa-save"></i>
                              </button>
                              <button
                                class="btn bg-danger"
                                @click="cancelPlanilla()"
                              >
                                <i class="fas fa-trash"></i>
                              </button>
                            </div>
                            <div
                              class="btn-group"
                              v-if="
                                estado_planilla == 2 &&
                                  id_actualizar_planilla !== indice
                              "
                            >
                              <button
                                type="button"
                                class="btn btn-sm bg-navy"
                                @click="editPlanilla(indice)"
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.planillaRedireccionamiento.editDetalle'
                                  )
                                "
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                            </div>
                          </td>
                          <td class="text-center">{{ indice + 1 }}</td>
                          <td class="text-center text-wrap">
                            {{ detalle.ruta_ini.nombre }}
                          </td>
                          <td class="text-center text-wrap">
                            {{ detalle.ruta_fin.nombre }}
                          </td>
                          <td
                            class="text-center text-wrap"
                            v-if="detalle.tramo == 1"
                          >
                            {{ detalle.ruta_reconocer.nombre }}
                          </td>
                          <td class="text-center text-wrap" v-else>
                            {{
                              detalle.ruta_reconocer.nombre +
                                " / " +
                                detalle.ruta_reconocer.nombre
                                  .split(" - ")
                                  .reverse()
                                  .join(" - ")
                            }}
                          </td>
                          <td class="text-center">
                            {{ detalle.tipo_vehiculo.nombre }}
                          </td>
                          <td class="text-center">
                            {{ detalle.producto.nombre }}
                          </td>
                          <td class="text-center">
                            {{ detalle.det_guia.fecha_expedicion }}
                          </td>
                          <td class="text-center text-wrap">
                            {{ detalle.guia_nueva.numero }} -
                            {{ detalle.guia_nueva.digito_verificacion }}
                          </td>
                          <td class="text-center">
                            {{ detalle.vehiculo.placa }}
                          </td>
                          <td class="text-center text-wrap">
                            <div
                              v-if="
                                id_actualizar_planilla === indice &&
                                  estado_planilla == 2
                              "
                            >
                              <textarea
                                class="form-control"
                                aria-label="With textarea"
                                v-model="formPlanilla.concepto"
                                :class="
                                  $v.formPlanilla.concepto.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              ></textarea>
                            </div>
                            <div v-else>
                              {{ detalle.concepto }}
                            </div>
                          </td>
                          <td class="text-center">
                            {{ detalle.gsv }}
                          </td>
                          <td class="text-center">
                            <div
                              v-if="
                                id_actualizar_planilla === indice &&
                                  estado_planilla == 2
                              "
                            >
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                v-model="formPlanilla.tramo"
                                :class="
                                  $v.formPlanilla.tramo.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @input="calcTotal(indice)"
                              />
                            </div>
                            <div v-else>
                              {{ detalle.tramo }}
                            </div>
                          </td>
                          <td class="text-center">
                            {{ detalle.valorTarifa }}
                          </td>
                          <td class="text-center">
                            {{ detalle.valorTotal }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  class="alert alert-default-warning border border-warning col-md-12 shadow"
                  v-else
                >
                  <h5 class="text-center">
                    <i class="icon fas fa-exclamation-triangle"></i>
                    Atención!
                  </h5>
                  <p class="text-center mb-0">
                    No se encontrarón viajes con redireccionamiento con los
                    datos
                    <strong
                      >{{ form.empresa.razon_social }},
                      {{ form.fecha_inicial }} - {{ form.fecha_final }} </strong
                    >.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";

export default {
  name: "PlanillasRedireccionamientoForm",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      empresa: {},
      detalles: [],
      formActualizar: false,
      id_actualizar_planilla: -1,
      estado_planilla: -1,
      formPlanilla: {
        tramo: null,
        concepto: null,
      },
      form: {
        fecha_inicial: null,
        fecha_final: null,
        fecha_ini_ant: null,
        fecha_fin_ant: null,
        empresa_id: null,
        estado: null,
      },
      accion: null,
      metodo: null,
      listasForms: {
        empresas: [],
        estados: [],
      },
    };
  },
  validations() {
    return {
      form: {
        fecha_inicial: {
          required,
        },
        fecha_final: {
          required,
        },
        empresa_id: {
          required,
        },
        estado: {
          required,
        },
      },

      formPlanilla: {
        tramo: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(2),
        },
        concepto: {
          required,
        },
      },
    };
  },

  methods: {
    async getIndex() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.metodo = "PUT";
        this.form = this.$route.params.data_edit;
        this.form.fecha_ini_ant = this.form.fecha_inicial;
        this.form.fecha_fin_ant = this.form.fecha_final;
        this.empresa = this.form.empresa;
        await this.getDetGuias();
        this.cargando = false;
      } else {
        this.metodo = "POST";
        this.form.estado = 1;
        this.cargando = false;
      }
      await this.getEstados();
      this.cargando = false;
    },

    async getEstados() {
      await axios.get("/api/lista/205").then((response) => {
        let respuesta = response.data;
        let lista = [];
        if (this.accion == "Crear") {
          for (let i = 0; i < respuesta.length; i++) {
            if (![2, 3].includes(respuesta[i].numeracion)) {
              lista.push({
                descripcion: respuesta[i].descripcion,
                estado: respuesta[i].estado,
                id: respuesta[i].id,
                numeracion: respuesta[i].numeracion,
              });
            }
          }
        } else {
          for (let i = 0; i < respuesta.length; i++) {
            let sinTarifa = this.detalles.every(
              (det) => det.tarifa == null || det.tarifa == 0
            );
            let sinPlanilla = this.detalles.every(
              (det) => det.planilla_redireccionamiento_id == null
            );
            if (sinTarifa || sinPlanilla) {
              if (![2, 3].includes(respuesta[i].numeracion)) {
                lista.push({
                  descripcion: respuesta[i].descripcion,
                  estado: respuesta[i].estado,
                  id: respuesta[i].id,
                  numeracion: respuesta[i].numeracion,
                });
              }
            } else {
              lista.push({
                descripcion: respuesta[i].descripcion,
                estado: respuesta[i].estado,
                id: respuesta[i].id,
                numeracion: respuesta[i].numeracion,
              });
            }
          }
        }
        this.listasForms.estados = lista;
      });
    },

    async getEmpresas() {
      await axios({
        method: "GET",
        url: "/api/admin/empresas/lista",
        params: {
          linea_negocio_id: 2,
        },
      }).then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    getSelectEmpresas() {
      this.form.empresa_id = null;
      if (this.empresa) {
        if (this.accion == "Crear") {
          this.form.empresa_id = this.empresa.id;
        } else {
          if (
            this.detalles &&
            this.detalles.length > 0 &&
            this.detalles.every(
              (det) => det.planilla_redireccionamiento_id != null
            )
          ) {
            this.$swal({
              title: "Está seguro de cambiar esta empresa.",
              text:
                "Ya se encuentran detalles asignados a esta planilla con la empresa " +
                this.form.empresa.razon_social +
                ", por favor validar nuevamente y aceptar si está seguro, de lo contrario por favor cancelar.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Aceptar!",
            }).then((result) => {
              if (result.value) {
                this.form.empresa_id = this.empresa.id;
              } else {
                this.form.empresa_id = this.form.empresa.id;
                this.empresa = this.form.empresa;
              }
            });
          } else {
            this.form.empresa_id = this.empresa.id;
          }
        }
      }
    },

    validaFechasForm() {
      if (this.form.fecha_inicial && this.form.fecha_final) {
        const fecha_inicial = new Date(this.form.fecha_inicial).getTime();
        const fecha_final = new Date(this.form.fecha_final).getTime();
        if (fecha_inicial > fecha_final) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha final no puede ser menor que la inicial`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    back() {
      return this.$router.replace("/Hidrocarburos/PlanillasRedireccionamiento");
    },

    async save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        this.form.estado = this.form.estado == 2 ? 3 : 1;
        await axios({
          method: this.metodo,
          url: "/api/hidrocarburos/planillasRedireccionamiento",
          data: this.form,
        })
          .then(async (response) => {
            this.$route.params.data_edit = response.data;
            this.$route.params.accion = "Editar";
            await this.getIndex();
            await this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    async getDetGuias() {
      await axios
        .get(
          "/api/hidrocarburos/planillasRedireccionamiento/viajesRedireccionados",
          {
            params: {
              fecha_ini: this.form.fecha_inicial,
              fecha_fin: this.form.fecha_final,
              empresa_id: this.form.empresa_id,
            },
          }
        )
        .then((response) => {
          this.detalles = response.data;
          for (let index = 0; index < this.detalles.length; index++) {
            let tramo = this.detalles[index].tramo
              ? parseFloat(this.detalles[index].tramo)
              : 1;
            let gsv = parseFloat(this.detalles[index].gsv) * 42;
            let total =
              gsv *
              parseFloat(this.detalles[index].ruta_reconocer.tarifa) *
              tramo;
            this.detalles[index].total = total;
            this.detalles[index].valorTotal = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              total
            );

            this.detalles[index].valorTarifa = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              this.detalles[index].ruta_reconocer.tarifa
            );
          }
        });
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },

    async saveDetalle() {
      await this.$swal({
        title: "Esta seguro de guardar estos detalles?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Guardar!",
      }).then(async (result) => {
        if (result.value) {
          this.cargando = true;
          let data = {
            detalles: this.detalles,
            planilla_redireccionamiento_id: this.form.id,
          };
          await axios({
            method: "PUT",
            url: "/api/hidrocarburos/planillasRedireccionamiento/saveDetalle",
            data: data,
          })
            .then(async (response) => {
              await this.getIndex();
              await this.$swal({
                icon: response.data.estado == 0 ? "success" : "error",
                title: response.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vualeva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            });
        }
      });
    },

    editPlanilla(indice) {
      this.id_actualizar_planilla = indice;
      this.estado_planilla = 2;
      this.formPlanilla = { ...this.detalles[indice] };
    },

    saveUpdatePlanilla(indice) {
      this.detalles[indice] = this.formPlanilla;
      this.estado_planilla = -1;
    },

    cancelPlanilla() {
      this.estado_planilla = -1;
    },

    calcTotal(indice) {
      let tramo = parseFloat(this.formPlanilla.tramo);
      let gsv = parseFloat(this.formPlanilla.gsv) * 42;
      let total =
        gsv * parseFloat(this.formPlanilla.ruta_reconocer.tarifa) * tramo;
      this.formPlanilla.total = total;
      this.formPlanilla.valorTotal = this.formatCurrency(
        "es-CO",
        "COP",
        2,
        total
      );

      this.detalles[indice].valorTotal = this.formatCurrency(
        "es-CO",
        "COP",
        2,
        total
      );

      this.formPlanilla.valorTarifa = this.formatCurrency(
        "es-CO",
        "COP",
        2,
        this.formPlanilla.ruta_reconocer.tarifa
      );
    },
  },

  async mounted() {
    await this.getEmpresas();
    await this.getIndex();
  },
};
</script>
